<script setup>
  import orderOs from '@/views/order/compontent/order-os'
  import { OrderStatus2 } from '@/enum/haier'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  import dayjs from 'dayjs'

  const $emit = defineEmits(['updataStatus'])
  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    addressInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const orderOsRef = ref()
  const data = reactive({
    //订单状态 0未支付，1支付中，2待发货，3已发货，4已收货，5关闭CLOSED
    orderStatusJO: { CLOSED: -1, NOPAID: 0, PAYING: 1, PAYMENT_FAILED: 2, UNDISTRIBUTED: 3, DISTRIBUTED: 4, RECEIPTED: 5 },
    // 退款申请状态[未处理,同意申请,拒绝申请,同意退款,关闭,特殊退款]
    refundApplyStatusJO: { CLOSED: -1, NODISPOSED: 0, AGREEAPPLY: 1, REFUSEAPPLY: 1, AGREEREFUND: 2, SPECIALREFUND: 3 },
    deadline: ''
  })

  watch(
    () => props.orderInfo.autoCloseOrderDate,
    (newVal, oldVal) => {
      data.deadline = Date.parse(props.orderInfo.autoCloseOrderDate)
    },
    { immediate: true }
  )

  // 传入状态更改状态专属图标和文本内容
  const getOrderStatusByType = (type) => {
    if (!type) return
    const status = OrderStatus2.find((status) => status.type === type)
    return status || null
  }

  /**
   * 根据订单状态和状态数组判断是否高亮状态图标
   * @param orderstatus
   * @param statusId
   * @returns {boolean}
   */
  const disableDiv = (orderstatus, statusId) => {
    // if (
    //   props.orderInfo.orderStatus === 'PAYMENT_FAILED' &&
    //   (props.orderInfo.channel === 'CARD' || props.orderInfo.channel === 'EASY_PAISA' || props.orderInfo.channel === 'JAZZ_CASH')
    // ) {
    //   return true
    // }
    return data.orderStatusJO[orderstatus] < data.orderStatusJO[statusId]
  }

  /**
   * 根据订单退款状态和状态数组判断是否高亮状态图标
   * @param orderstatus
   * @param statusId
   * @returns {boolean}
   */
  const refundDisableDiv = (orderstatus, statusId) => {
    return data.refundApplyStatusJO[orderstatus] < data.refundApplyStatusJO[statusId]
  }

  // 跳转购买页
  const toPay = () => {
    orderOsRef.value.toPay(props.orderInfo)
  }

  // 上传凭证处理
  const handleCredentials = () => {
    orderOsRef.value.handleCredentials(props.orderInfo)
  }

  // 确认收货
  const confirmReceive = () => {
    orderOsRef.value.confirmReceive(props.orderInfo, updataStatus)
  }

  // 取消订单
  const cancelOrder = () => {
    console.log('取消订单')
    orderOsRef.value.cancelOrder(props.orderInfo, updataStatus)
  }

  const updataStatus = (status) => {
    $emit('updataStatus', {
      status
    })
  }
</script>

<template>
  <div class="order-status-box">
    <div class="order_wc rowBC">
      <div class="order_zhuangt columnCC">
        <div class="order-num-box">
          <template v-if="props.orderInfo.orderStatus === 'NOPAID' || props.orderInfo.orderStatus == 'PAYING' || props.orderInfo.orderStatus == 'PAYMENT_FAILED'">
            <el-countdown format="DD [days] HH:mm:ss" :value="data.deadline" class="deadline" />
          </template>
          <div class="order_num">{{ $t('Order Number') }}:{{ props.orderInfo.mainOrderNo }}</div>
        </div>
        <div class="" data-remark="订单状态" v-if="getOrderStatusByType(props.orderInfo.orderStatus)">
          <template v-if="props.orderInfo.orderStatus == 'PAYING' || props.orderInfo.orderStatus == 'NOPAID'">
            <div class="icon">
              <!-- https://cdn.qingmeidata.com/719107a5.png -->
              <!-- {{ getOrderStatusByType(props.orderInfo.orderStatus).icon }} -->
              <img :src="getOrderStatusByType(props.orderInfo.orderStatus).icon" />
            </div>
            <div class="txt">Payment Pending</div>
          </template>
          <template v-else>
            <div class="icon">
              <img :src="getOrderStatusByType(props.orderInfo.orderStatus).icon" />
            </div>
            <div class="txt">{{ getOrderStatusByType(props.orderInfo.orderStatus).lable }}</div>
          </template>
        </div>

        <template v-if="props.orderInfo.orderStatus === 'NOPAID' || props.orderInfo.orderStatus == 'PAYING' || props.orderInfo.orderStatus == 'PAYMENT_FAILED'">
          <button class="btn mt5" v-if="props.orderInfo.channel === 'CARD' || props.orderInfo.channel === 'EASY_PAISA' || props.orderInfo.channel === 'JAZZ_CASH'" @click="toPay">
            {{ $t('Confirm') }}
          </button>
          <a class="btn2 mt5" v-else-if="props.orderInfo.channel === 'BANK_TRANSFER'" data-remark="0:待审核/1通过/-1审核不通过/2待提交" @click="handleCredentials">
            {{ props.orderInfo.bankTransferStatus === 0 ? $t('Awaiting Review') : props.orderInfo.bankTransferStatus === -1 ? $t('Rejected') : $t('Upload Credentials') }}
          </a>
        </template>
        <!-- props.orderInfo.orderStatus === 'NOPAID' || props.orderInfo.orderStatus == 'UNDISTRIBUTED' -->
        <template v-if="disableDiv(props.orderInfo.orderStatus, 'DISTRIBUTED') && props.orderInfo.orderStatus !== 'CLOSED'" data-remark="订单状态-待支付|待发货">
          <button class="btn mt5" data-remark="取消订单" @click="cancelOrder">{{ $t('Cancel') }}</button>
        </template>
        <!-- <template v-if="props.orderInfo.orderStatus === 'DISTRIBUTED'" data-remark="订单状态-已发货">
          <button class="btn mt5" data-remark="确认收货" @click="confirmReceive">{{ $t('Confirm') }}</button>
        </template> -->
        <template v-if="props.orderInfo.orderStatus === 'RECEIPTED'" data-remark="订单状态-已收货">
          <button class="btn mt5" data-remark="增加评论" v-if="0" @click="confirmReceive">{{ $t('Add Review') }}</button>
          <button class="btn mt5" data-remark="退款流程" v-if="0" @click="confirmReceive">{{ $t('Refund') }}</button>
        </template>

        <template v-if="0" data-remark="订单状态-退款关闭">
          <div class="btn" @click="refundCloseReq(refund.id)" v-if="'CLOSED' !== props.orderInfo.orderStatus && 'AGREEREFUND' !== props.orderInfo.orderStatus">
            {{ $t('Close') }}
          </div>
        </template>
      </div>
      <div class="order_liuc_body" data-remark="订单状态-状态展示">
        <div class="widthPC-100 rowCS" data-remark="订单状态-物流流程">
          <div class="order_liuc_item" :class="{ disabled: disableDiv(props.orderInfo.orderStatus, 'CLOSED') }" data-remark="订单状态-是否关闭">
            <img src="~img/order_liucheng_01.svg" />
            <div class="title">{{ $t('Order Place') }}</div>
            <div class="time" v-if="(props.orderInfo.orderStatus, 'CLOSED')">{{ JLTools.formatDateTime(props.orderInfo.mainOrderCreatedDate) }}</div>
          </div>
          <div class="order_liuc_line"></div>
          <div class="order_liuc_item" :class="{ disabled: disableDiv(props.orderInfo.orderStatus, 'DISTRIBUTED') }" data-remark="订单状态-是否已发货">
            <img src="~img/order_liucheng_03.svg" />
            <div class="title">Shipped</div>
          </div>
          <div class="order_liuc_line"></div>
          <div class="order_liuc_item" :class="{ disabled: disableDiv(props.orderInfo.orderStatus, 'RECEIPTED') }" data-remark="订单状态-是否已收货">
            <img src="~img/order_liucheng_04.svg" />
            <div class="title">Received</div>
          </div>
          <div class="order_liuc_line"></div>
          <div data-remark="订单状态-状态是否正常">
            <div v-if="props.orderInfo.orderStatus === 'CLOSED'" class="order_liuc_item" :class="{ disabled: false }">
              <img src="~img/cancelled.svg" />
              <div class="title">{{ $t('CANCELLED') }}</div>
            </div>
            <div v-else class="order_liuc_item" :class="{ disabled: disableDiv(props.orderInfo.orderStatus, 'RECEIPTED') }">
              <img src="~img/order_liucheng_05.svg" />
              <div class="title">{{ $t('Complete') }}</div>
            </div>
          </div>
        </div>
        <div class="widthPC-100 rowCC" v-if="0" data-remark="订单状态-退款流程">
          <div class="order_liuc_item" :class="{ disabled: refundDisableDiv(props.orderInfo.orderStatus, 'NODISPOSED') }" data-remark="订单状态-是否关闭">
            <img src="~img/order_liucheng_th1.svg" />
            <div class="title">{{ $t('Applying') }}</div>
            <div class="time" v-if="(props.orderInfo.orderStatus, 'NODISPOSED')">{{ JLTools.formatDateTime(props.orderInfo.mainOrderCreatedDate) }}</div>
          </div>
          <div class="order_liuc_line"></div>
          <div>
            <div class="order_liuc_item" :class="{ disabled: refundDisableDiv(props.orderInfo.orderStatus, 'REFUSEAPPLY') }" data-remark="订单状态-是否已发货">
              <img src="~img/order_liucheng_th4.svg" />
              <div class="title">{{ $t('Order Dispatched') }}</div>
            </div>
          </div>
          <div class="order_liuc_line"></div>
          <div class="order_liuc_item" :class="{ disabled: refundDisableDiv(props.orderInfo.orderStatus, 'AGREEREFUND') }" data-remark="订单状态-是否已收货">
            <img src="~img/order_liucheng_th3.svg" />
            <div class="title">{{ $t('Agree to a refund') }}</div>
          </div>
          <div class="order_liuc_line"></div>
          <div class="order_liuc_item" :class="{ disabled: props.orderInfo.orderStatus !== 'CLOSED' }">
            <img src="~img/order_liucheng_th5.svg" />
            <span class="title">{{ $t('Close') }}</span>
          </div>
        </div>
      </div>
    </div>
    <order-os ref="orderOsRef"></order-os>
  </div>
</template>

<style lang="less" scoped>
  :deep(.el-statistic__number) {
    font-weight: 500;
  }
  .order-status-box {
    .order_wc {
      border-top-color: #71b247;
    }
    .order_zhuangt {
      width: 300px;
      min-height: 200px;
      margin: 25px 0;
      border-right: 1px dashed #e1e1e1;
      .icon {
        width: 50px;
        height: 50px;
        margin: 20px calc(50% - 25px) 0;
      }
      .order-num-box {
        margin-top: px;
        .deadline {
          text-align: center;
        }
      }
      .order_num {
        width: 100%;
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: #999;
      }
      .txt {
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
      .btn {
        width: 120px;
        height: 36px;
        border-radius: 24px;
        line-height: 28px;
        border: 1px solid var(--btnBgColor);
        background-color: #fff;
        color: var(--btnBgColor);
        text-align: center;
        &:hover {
          cursor: pointer;
          background: var(--btnBgColor);
          color: #fff;
          transition: background 0.3s;
        }
      }
      .btn2 {
        background-color: #fff;
        color: var(--btnBgColor);
        text-decoration: underline;
        margin-bottom: 8px;
      }
    }
    .order_liuc_body {
      width: calc(100% - 300px);
      .order_liuc_item {
        /* min-width: 100px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .title {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .time {
          color: #999;
        }
        img {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
          /* margin: 20px calc(50% - 25px) 10px; */
        }
        &.disabled {
          .title {
            color: #ccc !important;
          }
          img {
            transform: none;
            filter: grayscale(100%);
          }
        }
      }
      .order_liuc_line {
        width: 100px;
        height: 1px;
        background: #eee;
        margin: 50px 10px;
      }
    }
  }
</style>
