<script setup>
  import orderStatusInfo from './compontent/order-status'
  import addressInfo from './compontent/address-info'
  import orderList from '@/views/order/compontent/order-list'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    orderId: '', // 订单id
    orderInfo: {}, // 订单信息
    addressInfo: {} // 地址信息
  })

  // 查询订单详情
  const getOrderDetail = () => {
    data.loading = true
    api.order
      .ordersDetail({
        mainOrderNo: data.orderId
      })
      .then((res) => {
        console.log('订单详情', res)
        if (res && res.subOrders) {
          let subOrderSpecs = []
          data.orderInfo = res
          data.addressInfo = res.subOrders[0]
          console.log(data.orderInfo, 'data.orderInfo')
        }
      })
      .catch((error) => {
        proxy.$toast(error)
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 更新订单状态
  const updataStatus = (obj) => {
    console.log('更新状态', obj)
    if ( data.orderInfo.orderStatus) {
      data.orderInfo.orderStatus = obj.status
    }
  }

  // 跳转用户页面
  const toUserInfo = () => {
    userStore.setUserShowPage({ page: 'orderList' })
    router.replace({ name: 'USERINFO' })
  }

  // 跳转首页
  const toHome = () => {
    router.replace({ name: 'home' })
  }

  // 初始化
  const init = () => {
    if (JLTools.isNull(data.orderId)) return
    getOrderDetail()
  }

  const shouldShowOrderInfo = () => {
      const { orderStatus, autoCloseOrderDate } = data.orderInfo
      const currentTime = new Date().getTime()
      
      return (
        (orderStatus === 'NOPAID' || orderStatus === 'PAYING' || orderStatus === 'PAYMENT_FAILED') &&
        (new Date(autoCloseOrderDate).getTime() > currentTime)
      )
    }

  // 函数：将时间戳转换为倒计时格式（带天数）
  function timestampToCountdownWithDays(timestamp) {
    // 转换时间戳为日期对象
    const targetDate = new Date(timestamp)
    const currentDate = new Date()

    // 计算剩余时间（毫秒为单位）
    const timeDifference = targetDate.getTime() - currentDate.getTime()

    // 计算天数、小时、分钟和秒
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

    // 格式化为倒计时格式（带天数或不带天数）
    let formattedCountdown = ''
    if (days > 0) {
      formattedCountdown += `${days}d `
    }
    formattedCountdown += `${hours.toString().padStart(2, '0')}hrs ${minutes.toString().padStart(2, '0')}mins`

    return formattedCountdown
  }

  onMounted(() => {
    // console.info(useRoute().params)
    data.orderId = useRoute().params.orderId || ''
    init()
  })
</script>

<template>
  <div class="content" v-if="data.orderId">
    <div class="breadcrumb-box rowSC">
      <span @click="toHome">{{ $t('Home') }}</span>
      <img class="img" src="~img/down_icon.svg" />
      <span @click="toUserInfo">{{ $t('Orders') }}</span>
      <img class="img" src="~img/down_icon.svg" />
      <b>{{ $t('Order Number') }}:{{ data.orderId }}</b>
    </div>

    <div class="order-box" v-loading="data.loading">
      <div class="hint-box rowCC" v-if="shouldShowOrderInfo() && data?.orderInfo.channel !== 'COD'">
        <div>Remaining payment time : After</div>
        <el-countdown format="HH [hrs] mm [mins] ss [secs]" :value="Date.parse(data?.orderInfo.autoCloseOrderDate)" class="deadline rowCC" />
        <div>Order will be cancelled</div>
      </div>
      <div class="order-info-card" data-remark="订单状态信息">
        <order-status-info :orderInfo="data.orderInfo" @updataStatus="updataStatus" />
      </div>

      <div class="order-info-card" data-remark="地址信息">
        <address-info :orderInfo="data.orderInfo" :addressInfo="data.addressInfo" />
      </div>

      <div class="order-info-card" data-remark="总订单商品列表">
        <order-list :orderInfo="data.orderInfo" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  :deep(.el-statistic__content) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    .el-statistic__number {
      color: #fff;
      font-size: 12px;
    }
  }
  .content {
    width: var(--minWidth);
    margin: 0 auto;
  }
  .breadcrumb-box {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    line-height: 30px;
    span {
      cursor: pointer;
    }
    .img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
  }

  .order-box {
    width: 100%;
    min-height: 480px;
    .order-info-card {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 0 0 15px #eee;
    }
  }

  .hint-box {
    padding: 6px;
    margin-bottom: 6px;
    background: var(--btnBgColor);
    color: #fff;
    text-align: center;
  }
</style>
