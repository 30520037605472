<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    addressInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const transformString = (str) => {

    if (!str) return
    // 将下划线替换为空格，并将字符串转换为小写
    let modifiedStr = str.replace(/_/g, ' ').toLowerCase()
    // 将每个单词的首字母大写
    modifiedStr = modifiedStr.replace(/\b\w/g, function (char) {
      return char.toUpperCase()
    })
    if (modifiedStr === 'Cod') {
      modifiedStr = 'COD'
    }
    return modifiedStr
  }
</script>

<template>
  <div class="address-info-box rowSC">
    <div class="order_user_card" data-remark="地址信息">
      <div class="title">{{ $t('Consignee Information') }}</div>
      <div class="item rowSC">
        <span>{{ $t('Consignee') }}:</span>
        <span>{{ props.addressInfo.consignee }}</span>
      </div>
      <div class="item rowSC">
        <span>{{ $t('Address') }}:</span>
        <span class="text_hide3">{{ props.addressInfo.address }} {{ props.addressInfo.district }} {{ props.addressInfo.city }} {{ props.addressInfo.province }}</span>
      </div>
      <div class="item rowSC">
        <span>{{ $t('Phone Number') }}:</span>
        <span>+92 {{ props.addressInfo.mobile }}</span>
      </div>
    </div>
    <div class="order_user_card" data-remark="购买信息">
      <div class="title">{{ $t('Customer Information') }}</div>
      <div class="item rowSC">
        <span>{{ $t('Customer') }}:</span>
        <span v-if="userStore.userInfo.nickname">{{ userStore.userInfo.nickname }}</span>
      </div>
    </div>
    <div class="order_user_card" data-remark="支付信息">
      <div>
        <div class="title">{{ $t('Payment Information') }}</div>
        <div>
          <div class="item rowSC">
            <span>{{ $t('Channel') }}:</span>
            <span>{{ transformString(props.orderInfo.channel) }}</span>
          </div>
          <div class="item rowSC">
            <span>{{ $t('Amount') }}:</span>
            <span>{{ JLTools.forPrice(props.orderInfo.totalPaidAmount) }}</span>
          </div>
          <div class="item rowSC" v-if="0">
            <span>{{ $t('transactionID') }}:</span>
            <span>{{ props.orderInfo.mainOrderNo }}</span>
          </div>
        </div>
        <!-- <template v-else>
          <div class="btn" @click="toPage('PAYMENT')">{{ $t('To pay') }}</div>
        </template> -->
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .address-info-box {
    .order_user_card {
      width: 33.333%;
      min-height: 120px;
      border-left: 1px dashed #e1e1e1;
      margin: 25px 0;
      padding: 0 25px;
      &:first-child {
        border-left: 0px;
      }
      .title {
        width: 100%;
        font-size: 14px;
      }
      .item {
        width: 100%;
        line-height: 20px;
        margin-top: 10px;
        span {
          &:first-child {
            margin-right: 10px;
            color: #999;
          }
        }
      }
    }
  }
</style>
